export default {
	data() {
		return {
			loading: false,
			feedbackUedact: {},
			showUpload: false,
			//创建版本验证
			UedactFeedbackRules: {
				remark: [{
					required: true,
					message: '请输入处理意见',
					trigger: 'blur'
				}]
			},
		}
	},
	created() {
		this.feedbackUedact = this.$route.query.feedbackUedact;
	},
	methods: {
		submitForm(feedbackUedact) {
			this.$refs[feedbackUedact].validate((valid) => {
				if (valid) {
					this.$axios({
						method: "post",
						url: "/api/cgm/web/patientFeedback/update", // 接口地址
						data: this.feedbackUedact
					}).then(res => {
						if (res.data.code === 200) {
							this.$router.push({
								path: 'feedback'
							});
							this.$message({
								message: "修改成功",
								type: "succeed",
							});
						} else {
							this.$message({
								message: "操作失败",
								type: "error",
							});
						}
					}).catch(error => {
						console.log(error);
						//this.$message.error("请求失败");
					});
				}
			})
		}
	}
}
